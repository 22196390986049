<template>
  <v-container fluid>
    <PageHeaderSlot :isLoading="$store.getters.isLoading"> </PageHeaderSlot>
    <v-row>
      <v-col cols="12">
        <v-form ref="dataForm" @submit.prevent="save">
          <v-card class="mb-8">
            <v-card-text>
              <v-row>
                <v-col cols="12">
                    <h2>公司簡介</h2>
                </v-col>
                <v-col cols="12">
                  <FormTextarea
                    :fieldValue.sync="formData.introduction"
                    dispatchUpdateOnChange
                  ></FormTextarea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-btn
            depressed
            color="primary accent-4"
            class="white--text"
            type="submit"
            :loading="$store.getters.isLoading"
          >
            儲存
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'

import FormTextarea from '@/components/form/FormTextarea.vue'

export default {
  name: 'Setting',
  components: {
    FormTextarea,
  },
  data: () => ({
    rowCount: 0,
    formData: {
      introduction: '',
    },
  }),

  methods: {
    ...mapActions(['setDialogMessage', 'setShowDialog', 'setLeaveDialogLink', 'setShowLeaveDialog']),
    async getSetting() {
      try {
        const data = await this.$Fetcher.GetSetting()
        this.$set(this.formData, 'introduction', data.introduction)
      } catch (err) {
        this.$common.error(err)
      } finally {
        this.$store.dispatch('toggleLoadingPage', false)
      }
    },

    async save() {
      if (this.$store.getters.isLoading) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'processing',
          type: 'error',
          refresh: false,
          redirect: '',
        })
        return
      }

      this.$store.dispatch('setLoading', true)
      const valid = await this.$refs['dataForm'].validate()
      if (!valid) {
        this.$store.dispatch('setLoading', false)
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: 'required',
          type: 'error',
          refresh: false,
          redirect: '',
        })
        return
      }

      const payload = {
        introduction: this.formData.introduction,
      }

      try {
        await this.$Fetcher.SetSetting(payload)
        this.$store.dispatch('setDataIsUpdated', false)
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: '儲存成功',
          type: 'success',
          refresh: true,
          redirect: '',
        })
      } catch (err) {
        this.setDialogMessage({
          title: '儲存失敗',
          message: err,
          isError: true,
          returnLink: null,
        })
        this.setShowDialog(true)
      } finally {
        this.$store.dispatch('setLoading', false)
      }
    },
  },
  async created() {
    this.$store.dispatch('toggleLoadingPage', true)
    this.getSetting()
  },

  // ------ navigation guard ------
  beforeRouteLeave(to, from, next) {
    if (this.$store.getters.isLoading) {
      this.$store.dispatch('toggleAlertMessage', {
        show: true,
        message: 'processing',
        type: 'error',
        refresh: false,
        redirect: '',
      })
      next(false)
    } else if (this.$store.getters.dataIsUpdated) {
      this.setLeaveDialogLink({ name: to.name })
      this.setShowLeaveDialog(true)
      next(false)
    } else {
      next()
    }
  },
}
</script>
